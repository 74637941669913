<template lang="pug">
    .image-timer
      ArticleWrapper(:style="{ 'margin-bottom': '200px'}")
        h1.mb-4
          | Image Countdown
          v-icon.ml-2(:color="time && (time.minutes || time.seconds) ? 'red' : 'accent'" x-large) mdi-clock

        LoadUrls.mt-6(v-if="!imageUrl", @update:image="handleUpdateImage", @reset="handleReset")

        v-row(v-if="imageUrl" dense)
          v-col(cols="12")
            Countdown(:currentTime="time", showRestart, @start="handleStartTimer", @reset="handleReset") 
          v-col(cols="12")
            DisplayImage(:prompt="prompt", :blur="blur", :imageUrl="imageUrl" @reset="handleReset", @click="handleStartTimer({type: 's', value:20})")
          v-col(cols="12")
            Countdown(:currentTime="time", showRestart, @start="handleStartTimer", @reset="handleReset") 
      
        
        v-card(v-if="!imageUrl", flat color="grey lighten-5").pa-5
          h3 What is this?
          p This is a <b>tool for teachers</b> to add a fun <em>"Race against the clock"</em> component to lessons.
          p Display an image for a set amount of time and let your students race to use it.

          h3 Examples
          ul.examples
            li.example(v-for="example, idx in examples", :key="example.image").mb-4
              v-btn(outlined small color="accent" @click.prevent="startExample(example)").mr-2 Try # {{idx+1}}
              | {{example.prompt}}

          h3 Instructions
          ol
            li.mb-3 Upload an image from your computer or from the web. The image will start out blurred.
            li Start the countdown and the image will be sharp and in-focus until the time runs out.

</template>

<script>
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
import LoadUrls from "@/components/Tools/ImageTimer/LoadUrls.vue";
import DisplayImage from "@/components/Tools/ImageTimer/DisplayImage.vue";
import Countdown from "@/components/Tools/ImageTimer/Countdown.vue";

import Timer from "easytimer.js";

export default {
  name: "ImageTimer",
  components: { ArticleWrapper, LoadUrls, DisplayImage, Countdown },
  data() {
    return {
      imageUrl: null,
      blur: true,
      time: null,
      timer: null,
      prompt: null,
      examples: [
        {
          image:
            "https://media.newyorker.com/cartoons/5fe405409fabedf20ec1fd6c/master/w_1600,c_limit/210104_a21352.jpg",
          prompt: "Q: Who are the characters and what are they saying?",
          countdown: 30,
        },
        {
          prompt:
            "Q: What is this image about? Read each stage and describe it.",
          countdown: 60,
          image:
            "https://media.newyorker.com/cartoons/6014d0d826ec5f7b490416d6/master/w_1280,c_limit/210208_a22741.jpg",
        },
        {
          prompt: "Q: What do you think happened right before this scene?",
          countdown: 60,
          image:
            "https://media.newyorker.com/photos/590977afebe912338a377e80/master/w_1600,c_limit/Fathers-Day_Flake_2013_01_14_0081598.jpg",
        },
      ],
    };
  },
  methods: {
    handleReset() {
      if (this.timer) {
        this.timer.stop();
        this.timer.removeEventListener("secondsUpdated");
      }

      this.imageUrl = null;
      this.blur = true;
      this.time = null;
      this.timer = null;
      this.prompt = null;
    },
    handleUpdateImage(e) {
      this.imageUrl = e;
      this.blur = true;
    },
    handleStartTimer(time) {
      if (this.timer) {
        this.timer.stop();
        this.timer.removeEventListener("secondsUpdated");
      }

      this.time = {
        minutes: time.type === "m" ? time.value : 0,
        seconds: time.type === "s" ? time.value : 0,
      };

      this.blur = false;
      this.timer = new Timer();

      const seconds = time.type === "s" ? time.value : time.value * 60;
      this.timer.start({
        precision: "seconds",
        countdown: true,
        startValues: { seconds },
      });

      const vm = this;
      this.timer.addEventListener("started", function () {
        vm.blur = false;
      });

      this.timer.addEventListener("stopped", function () {
        vm.blur = true;
      });

      this.timer.addEventListener("secondsUpdated", function () {
        const val = {
          minutes: vm.timer.getTimeValues().minutes,
          seconds: vm.timer.getTimeValues().seconds,
        };
        vm.time = val;
      });
    },
    startExample(example) {
      this.imageUrl = example.image;
      this.prompt = example.prompt;

      this.handleStartTimer({ type: "s", value: example.countdown });
    },
  },
};
</script>

<style lang="scss" scoped></style>
