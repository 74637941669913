<template lang="pug">
    v-card.grey.lighten-4.pa-4(flat tile)
        template(v-if="!imageIsLocal && !imageIsRemote")
            .text-center
                p My image is...
                v-btn(x-large, @click="handleLocalClick", tile, color="primary" , depressed, :block="$vuetify.breakpoint.smAndDown").mr-md-3 On this device...
                v-btn(x-large, @click="imageIsRemote = true", tile, color="primary", outlined :block="$vuetify.breakpoint.smAndDown") On the web...


                v-file-input(ref="imagePicker", v-model="image" outlined dense @change="handleFileChange").d-none
                
                
        
        template(v-else-if="imageIsRemote")
            .d-flex.align-center
                v-text-field(v-model="url" hide-details outlined  max-width="100px", placeholder="https://example.com/images/my_image.png").flex-grow-2.mr-3
                v-btn(color="primary" depressed x-large @click="$emit('update:image', url)").mr-md-2.flex-shrink-1 Upload...
                v-btn(color="primary" outlined x-large @click="()=>{imageIsRemote = null; $emit('reset')}").flex-shrink-1 Back
</template>

<script>
export default {
  name: "LoadUrls",
  data() {
    return {
      url: null,
      imageIsRemote: false,
      imageIsLocal: false,

      image: null,
      imageUrl: null,
    };
  },
  watch: {},
  methods: {
    handleLocalClick() {
      const ref = this.$refs.imagePicker;
      // console.log("Ref", ref);
      ref.$el.querySelector("input").click();
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageUrl = e.target.result;
        this.$emit("update:image", this.imageUrl);
      };
      reader.readAsDataURL(file);
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
  },
};
</script>
